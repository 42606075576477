"use strict";

import "./index.css";
import React, { Component } from "react";
import { Switch, NavBar, Checkbox, Radio, Input } from "antd-mobile";

import "./common.css";

const print = function (e) {};
class DDS_lanhupage_0 extends Component {
    state = {};
    setValue(val) {}
    render() {
        return (
            <div className="page flex-col">
                <div className="group_1 flex-row">
                    <div className="group_31 flex-col">
                        <img className="image_1" src={`${process.env.PUBLIC_URL}/images/70a4a558.png`} />
                        <span className="text_1">AETHERIAN&nbsp;CHRONICLES</span>
                        <span className="text_2">Supporting&nbsp;Material</span>
                        <div className="text-group_13 flex-col justify-between">
                            <span className="text_3">KingFisher&nbsp;Platforms</span>
                            <span className="paragraph_1">
                                Concept&nbsp;Art&nbsp;and&nbsp;In Progress&nbsp;Videos&nbsp;of&nbsp;
                                <br />
                                Aetherian&nbsp;Chronicles
                            </span>
                        </div>
                    </div>
                    <img className="image_2" src={`${process.env.PUBLIC_URL}/images/28da49a9.png`} />
                    <img className="image_3" src={`${process.env.PUBLIC_URL}/images/eb6cbed6.png`} />
                </div>
                <div className="group_2 flex-col">
                    <div className="text-wrapper_8 flex-row">
                        <span className="text_4">Branding&nbsp;concept</span>
                    </div>
                    <div className="section_8 flex-row justify-between">
                        <div className="image-wrapper_1 flex-col">
                            <img className="image_4" src={`${process.env.PUBLIC_URL}/images/34f87cd0.png`} />
                        </div>
                        <div className="group_32 flex-col justify-between">
                            <div className="image-wrapper_2 flex-col">
                                <img className="image_5" src={`${process.env.PUBLIC_URL}/images/2a81059e.png`} />
                            </div>
                            <div className="image-wrapper_3 flex-col">
                                <img className="image_6" src={`${process.env.PUBLIC_URL}/images/e9fe6276.png`} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="group_3 flex-col">
                    <span className="text_5">Trailer&nbsp;Video</span>
                    <div className="group_4 flex-col">
                        <div className="image-wrapper_4 flex-col">
                            <iframe
                                className="youtube-video"
                                style={{ width: '100%', height: '100%' }}
                                src="https://www.youtube.com/embed/i_ezbel7Z-0?si=Sy6cqqykCni3eSMU"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                        <div className="box_4 flex-row justify-between">
                            <div className="group_6 flex-col" />
                            <div className="group_7 flex-col" />
                        </div>
                    </div>
                </div>
                <div className="group_8 flex-col">
                    <span className="text_6">user&nbsp;interface</span>
                    <div className="group_33 flex-row justify-between">
                        <img className="image_8" src={`${process.env.PUBLIC_URL}/images/6b69bd2c.png`} />
                        <div className="section_3 flex-col">
                            <div className="box_5 flex-row">
                                <div className="block_1 flex-row">
                                    <img className="thumbnail_1" src={`${process.env.PUBLIC_URL}/images/807e2be4.png`} />
                                    <img className="thumbnail_2" src={`${process.env.PUBLIC_URL}/images/24f1292a.png`} />
                                    <img className="thumbnail_3" src={`${process.env.PUBLIC_URL}/images/97cebd92.png`} />
                                    <img className="thumbnail_4" src={`${process.env.PUBLIC_URL}/images/a9e4403e.png`} />
                                    <img className="thumbnail_5" src={`${process.env.PUBLIC_URL}/images/969d1145.png`} />
                                    <img className="thumbnail_6" src={`${process.env.PUBLIC_URL}/images/3cf55389.png`} />
                                    <img className="thumbnail_7" src={`${process.env.PUBLIC_URL}/images/9420b186.png`} />
                                    <img className="thumbnail_8" src={`${process.env.PUBLIC_URL}/images/9d3afab2.png`} />
                                    <img className="thumbnail_9" src={`${process.env.PUBLIC_URL}/images/28191dad.png`} />
                                    <div className="image-text_4 flex-row justify-between">
                                        <img className="thumbnail_10" src={`${process.env.PUBLIC_URL}/images/86d98041.png`} />
                                        <span className="text-group_2">80%</span>
                                    </div>
                                </div>
                                <img className="label_1" src={`${process.env.PUBLIC_URL}/images/03bb2771.png`} />
                            </div>
                            <div className="box_6 flex-row">
                                <div className="section_4 flex-row">
                                    <img className="thumbnail_11" src={`${process.env.PUBLIC_URL}/images/807514fa.png`} />
                                    <img className="thumbnail_12" src={`${process.env.PUBLIC_URL}/images/2d9cc1cf.png`} />
                                    <img className="thumbnail_13" src={`${process.env.PUBLIC_URL}/images/5156fec7.png`} />
                                    <img className="thumbnail_14" src={`${process.env.PUBLIC_URL}/images/b18ab722.png`} />
                                    <img className="thumbnail_15" src={`${process.env.PUBLIC_URL}/images/44e96dbf.png`} />
                                    <img className="thumbnail_16" src={`${process.env.PUBLIC_URL}/images/0f2e02f3.png`} />
                                    <img className="thumbnail_17" src={`${process.env.PUBLIC_URL}/images/8fbc687b.png`} />
                                    <img className="thumbnail_18" src={`${process.env.PUBLIC_URL}/images/747260fe.png`} />
                                    <img className="thumbnail_19" src={`${process.env.PUBLIC_URL}/images/ac1ef4d4.png`} />
                                    <div className="image-text_5 flex-row justify-between">
                                        <img className="thumbnail_20" src={`${process.env.PUBLIC_URL}/images/8842f018.png`} />
                                        <span className="text-group_3">70%</span>
                                    </div>
                                </div>
                                <img className="label_2" src={`${process.env.PUBLIC_URL}/images/90ff038e.png`} />
                            </div>
                            <div className="box_7 flex-row">
                                <div className="group_12 flex-row">
                                    <img className="thumbnail_21" src={`${process.env.PUBLIC_URL}/images/82d1565b.png`} />
                                    <img className="thumbnail_22" src={`${process.env.PUBLIC_URL}/images/9cad2f4b.png`} />
                                    <img className="thumbnail_23" src={`${process.env.PUBLIC_URL}/images/f2a70dd5.png`} />
                                    <img className="thumbnail_24" src={`${process.env.PUBLIC_URL}/images/5994b3e7.png`} />
                                    <img className="thumbnail_25" src={`${process.env.PUBLIC_URL}/images/8701fafd.png`} />
                                    <img className="thumbnail_26" src={`${process.env.PUBLIC_URL}/images/8c208043.png`} />
                                    <img className="thumbnail_27" src={`${process.env.PUBLIC_URL}/images/56a9e633.png`} />
                                    <img className="thumbnail_28" src={`${process.env.PUBLIC_URL}/images/ff0bf92c.png`} />
                                    <img className="thumbnail_29" src={`${process.env.PUBLIC_URL}/images/cfe570d4.png`} />
                                    <div className="image-text_6 flex-row justify-between">
                                        <img className="thumbnail_30" src={`${process.env.PUBLIC_URL}/images/86d98041.png`} />
                                        <span className="text-group_4">90%</span>
                                    </div>
                                </div>
                                <img className="label_3" src={`${process.env.PUBLIC_URL}/images/b670ae8e.png`} />
                            </div>
                            <div className="box_8 flex-row">
                                <div className="group_14 flex-col">
                                    <span className="text_7">shop</span>
                                    <img className="label_4" src={`${process.env.PUBLIC_URL}/images/a00fcac7.png`} />
                                </div>
                                <div className="group_15 flex-col">
                                    <span className="text_8">equip</span>
                                    <img className="label_5" src={`${process.env.PUBLIC_URL}/images/290954b6.png`} />
                                </div>
                                <div className="group_16 flex-col">
                                    <span className="text_9">inventory</span>
                                    <img className="label_6" src={`${process.env.PUBLIC_URL}/images/f47f58bd.png`} />
                                </div>
                            </div>
                            <div className="box_9 flex-row">
                                <div className="block_2 flex-col">
                                    <span className="text_10">skill</span>
                                    <img className="label_7" src={`${process.env.PUBLIC_URL}/images/be66a5d0.png`} />
                                </div>
                                <div className="block_3 flex-col">
                                    <span className="text_11">keyboard</span>
                                    <img className="image_9" src={`${process.env.PUBLIC_URL}/images/e1f12918.png`} />
                                </div>
                                <div className="block_4 flex-col">
                                    <span className="text_12">attribute</span>
                                    <img className="label_8" src={`${process.env.PUBLIC_URL}/images/86bf7b37.png`} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="list_2 flex-row">
                        <div className="list-items_1-0 flex-col" />
                        <div className="list-items_1-1 flex-col" />
                        <div className="list-items_1-2 flex-col" />
                    </div>
                </div>
                <div className="text-wrapper_9 flex-col">
                    <span className="text_13">Notable&nbsp;Features</span>
                </div>
                <div className="group_18 flex-col">
                    <span className="text_14">artificial&nbsp;intelligence</span>
                    <div className="text-group_14 flex-col justify-between">
                        <span className="text_15">Talking&nbsp;to&nbsp;Artificial&nbsp;Intelligence</span>
                        <span className="paragraph_2">
                            Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;descriptio
                        </span>
                    </div>
                    <div className="image-wrapper_13 flex-col">
                        <iframe
                            className="youtube-video"
                            style={{ width: '100%', height: '100%' }}
                            src="https://www.youtube.com/embed/i_ezbel7Z-0?si=Sy6cqqykCni3eSMU"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
                <div className="group_22 flex-col">
                    <div className="text-wrapper_10 flex-row">
                        <span className="text_19">equipment</span>
                    </div>
                    <div className="section_9 flex-row justify-between">
                        <div className="text-group_15 flex-col justify-between">
                            <span className="text_20">jumpsuit</span>
                            <span className="paragraph_3">
                                Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;
                                <br />
                                description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description&nbsp;
                                <br />
                                text&nbsp;for&nbsp;weapons
                            </span>
                        </div>
                        <div className="text-group_16 flex-col justify-between">
                            <span className="text_21">helmet</span>
                            <span className="paragraph_4">
                                Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;
                                <br />
                                description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description&nbsp;
                                <br />
                                text&nbsp;for&nbsp;weapons
                            </span>
                        </div>
                    </div>
                    <div className="image-wrapper_14 flex-row justify-between">
                        <img className="image_17" src={`${process.env.PUBLIC_URL}/images/equipment_pic1.png`} />
                        <img className="image_18" src={`${process.env.PUBLIC_URL}/images/equipment_pic5.png`} />
                    </div>
                    <div className="section_10 flex-row justify-between">
                        <div className="image-wrapper_15 flex-col justify-between">
                            <img className="image_19" src={`${process.env.PUBLIC_URL}/images/equipment_pic2.png`} />
                            <img className="image_20" src={`${process.env.PUBLIC_URL}/images/equipment_pic3.png`} />
                        </div>
                        <div className="text-group_17 flex-col justify-between">
                            <span className="text_22">Weapon</span>
                            <span className="paragraph_5">
                                Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;
                                <br />
                                description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description&nbsp;
                                <br />
                                text&nbsp;for&nbsp;weapons
                            </span>
                        </div>
                    </div>
                    <div className="section_11 flex-row">
                        <img className="image_21" src={`${process.env.PUBLIC_URL}/images/equipment_pic4.png`} />
                        <img className="image_22" src={`${process.env.PUBLIC_URL}/images/eb3b18f1.png`} />
                        <div className="section_5 flex-col" />
                        <div className="section_6 flex-col" />
                        <div className="section_7 flex-col" />
                    </div>
                    <img className="image_23" src={`${process.env.PUBLIC_URL}/images/2adbae5e.png`} />
                </div>
                <div className="group_26 flex-col">
                    <span className="text_23">MAPs</span>
                    <div className="box_10 flex-row justify-between">
                        <div className="box_11 flex-col">
                            <div className="text-group_18 flex-col justify-between">
                                <span className="text_24">map&nbsp;name</span>
                                <span className="text_25">
                                    Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description
                                </span>
                            </div>
                            <div className="image-wrapper_9 flex-col">
                                <img className="image_24" src={`${process.env.PUBLIC_URL}/images/1e9209d2.png`} />
                            </div>
                            <div className="text-group_19 flex-col justify-between">
                                <span className="text_26">map&nbsp;name</span>
                                <span className="text_27">
                                    Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description&nbsp;text&nbsp;for
                                </span>
                            </div>
                        </div>
                        <img className="image_25" src={`${process.env.PUBLIC_URL}/images/3f44680d.png`} />
                    </div>
                    <div className="image-wrapper_10 flex-row">
                        <img className="image_26" src={`${process.env.PUBLIC_URL}/images/694f9d45.png`} />
                        <img className="image_27" src={`${process.env.PUBLIC_URL}/images/f29206de.png`} />
                        <img className="image_28" src={`${process.env.PUBLIC_URL}/images/a295e017.png`} />
                    </div>
                </div>
                <div className="group_27 flex-col">
                    <div className="group_28 flex-col">
                        <div className="text-wrapper_11 flex-row justify-between">
                            <span className="text_28">Monsters</span>
                            <span className="text_29">Monsters&nbsp;name2</span>
                        </div>
                        <div className="group_34 flex-row justify-between">
                            <div className="text-group_20 flex-col justify-between">
                                <span className="text_30">Monsters&nbsp;name1g</span>
                                <span className="paragraph_6">
                                    Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;
                                    <br />
                                    description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description&nbsp;
                                    <br />
                                    text&nbsp;for&nbsp;weapons
                                </span>
                            </div>
                            <div className="box_12 flex-col justify-between">
                                <span className="text_31">
                                    Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description
                                </span>
                                <img className="image_29" src={`${process.env.PUBLIC_URL}/images/fa5c4692.png`} />
                            </div>
                        </div>
                        <div className="text-group_21 flex-col justify-between">
                            <span className="text_32">Monsters&nbsp;name3</span>
                            <span className="paragraph_7">
                                Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;
                                <br />
                                description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description&nbsp;
                                <br />
                                text&nbsp;for&nbsp;weapons
                            </span>
                        </div>
                        <img className="image_30" src={`${process.env.PUBLIC_URL}/images/95c1c28d.png`} />
                    </div>
                    <div className="box_13 flex-row justify-between">
                        <img className="image_31" src={`${process.env.PUBLIC_URL}/images/b0bd0cdc.png`} />
                        <div className="text-wrapper_12 flex-col justify-between">
                            <span className="text_33">Monsters&nbsp;name4</span>
                            <span className="text_34">
                                Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description&nbsp;text&nbsp;for&nbsp;weapons&nbsp;Design&nbsp;description
                            </span>
                        </div>
                    </div>
                    <div className="image-wrapper_11 flex-col">
                        <img className="image_32" src={`${process.env.PUBLIC_URL}/images/3f6b956a.png`} />
                    </div>
                    <img className="image_33" src={`${process.env.PUBLIC_URL}/images/4c58cac7.png`} />
                    <div className="image-wrapper_12 flex-col">
                        <img className="image_34" src={`${process.env.PUBLIC_URL}/images/5fbd5a98.png`} />
                    </div>
                </div>
            </div>
        );
    }
}
export default DDS_lanhupage_0;
